/* Sections 
================================================== */
section {
  position: relative;
  overflow: clip;
  padding: 4rem 0;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  @include bp(medium) {
    padding: 6rem 0;
  }
  .row {
    position: relative;
    z-index: 20;
  }
}

.section {
  &--narrow {
    padding: 3rem 0;
  }
  &--white-content {
    * {
      color: $white;
    }
    .primary-colour {
      color: $primary-colour;
    }
    .secondary-colour {
      color: $secondary-colour;
    }
    .tertiary-colour {
      color: $tertiary-colour;
    }
    a {
      font-weight: 600;
    }
  }
  &--colour-bg-1 {
    background-color: $primary-colour;
  }
  &--colour-bg-2 {
    background-color: $secondary-colour;
    ul li::marker {
      color: $tertiary-colour;
    }
  }
  &--colour-bg-3 {
    background-color: $tertiary-colour;
  }
  &--colour-bg-4 {
    background-color: $quaternary-colour;
    p,
    li {
      font-weight: 500;
    }
  }
  &--pattern-1 {
    &:after {
      content: '';
      position: absolute;
      z-index: 10;
      right: 0;
      bottom: 0;
      left: 0;
      height: 40%;
      background: url('#{$image-directory}dot-pattern-bg.svg') repeat 50% 50%;
      background-size: 25%;
    }
  }
  &--location {
    &:before,
    &:after {
      content: '';
      position: absolute;
    }
    &:before {
      z-index: 15;
      background-color: $tertiary-colour-dark;
      top: 0;
      left: 0;
      bottom: 0;
      width: 60%;
      opacity: 0.6;
      @media (max-width: 640px) {
        right: 0;
        bottom: auto;
        width: 100%;
        height: 60%;
      }
    }
    &:after {
      z-index: 10;
      inset: 0;
      background-color: $primary-colour;
      mix-blend-mode: exclusion;
    }
  }
  &--overlay,
  &--overlay-secondary,
  &--overlay-tertiary {
    section {
      &:before {
        content: '';
        position: absolute;
        inset: 0;
        background-color: $primary-colour;
        opacity: 0.8;
      }
    }
  }
  &--overlay-secondary {
    background-color: $secondary-colour;
  }
  &--overlay-tertiary {
    background-color: $tertiary-colour;
  }
}

.full-width-row {
  display: flex;
  gap: 0;
  position: relative;
  &--black,
  &--bg-1,
  &--bg-2,
  &--bg-3 {
    * {
      color: $white;
    }
  }
  &--bg-4 {
    * {
      color: $black;
    }
  }
  &--black {
    background-color: $black;
  }
  &--bg-1 {
    background-color: $primary-colour;
  }
  &--bg-2 {
    background-color: $secondary-colour;
    ul li::marker {
      color: $tertiary-colour;
    }
  }
  &--bg-3 {
    background-color: $tertiary-colour;
  }
  &--bg-4 {
    background-color: $quaternary-colour;
  }
  .row__content {
    z-index: 20;
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    padding: 4rem 2.5rem;
    @include bp(large) {
      padding: 6rem;
    }
    * {
      width: 100%;
    }
    .button {
      width: auto;
    }
  }
  .column--padding {
    padding: 4rem 2.5rem;
    @include bp(large) {
      padding: 6rem;
    }
  }
  figure {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100% !important;
    img {
      display: block;
      width: 100%;
      height: 100% !important;
      max-width: none;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }
  .button-wrap {
    * {
      width: auto;
    }
  }
  &--reverse {
    @include bp(mlarge) {
      flex-direction: row-reverse;
    }
  }
  &.wf-columns-layout-1-1 {
    &.wf-columns-stack-medium {
      .wf-column {
        @include bp(mlarge) {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }
    }
  }
}
