main {
  padding: 0;
}
.main-wrap {
  padding: 0;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.intro-section {
  min-height: 65vh;
  align-items: stretch;
  gap: 0;
  .wf-column {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 4rem 2rem;
    @include bp(large) {
      padding: 16rem 2rem;
    }
    * {
      position: relative;
      z-index: 20;
    }
    &:after {
      content: '';
      position: absolute;
      z-index: 10;
      inset: 0;
      opacity: 0.8;
    }
    figure {
      max-width: 480px;
      width: 100%;
      height: auto;
      margin-bottom: 2rem;
      padding: 2rem;
      background-color: $white;
      border-radius: 16px;
      text-align: center;
      img {
        display: block;
        object-fit: cover;
        width: 100%;
        height: auto;
        margin-bottom: 0.5rem;
      }
      p {
        margin: 0.25rem 0 0 0;
        font-weight: 500;
        font-size: 1rem;
        color: $secondary-colour;
        span {
          font-size: 1.2rem;
          font-weight: bold;
        }
      }
    }
  }
  &__left {
    &:after {
      background-color: $quaternary-colour;
    }
  }
  &__right {
    &:after {
      background-color: $tertiary-colour;
    }
  }
}

#second-section {
  section {
    min-height: 30vh;
  }
}

footer {
  min-height: 5vh;
}
