/* Select Event Button Switcher
================================================== */
.select-menu-active {
  .social-fixed {
    opacity: 0;
    right: -100px;
  }
}
.events-list {
  position: absolute;
  z-index: 200;
  top: 0;
  right: 0;
  display: flex;
  @include bp_max(medium) {
    width: 100%;
    left: 0;
  }
  p {
    margin: 0;
  }
  &__wrap {
    display: none;
    position: relative;
    z-index: 100;
    top: 100%;
    right: 0;
    padding: 1rem 1rem 0;
    background-color: rgba(255, 255, 255, 0.5);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    @include box-shadow(none);
    @include bp_max(medium) {
      width: 100%;
      left: 0;
    }
    .select-menu-active & {
      display: block;
    }
    .subheading {
      margin: 0;
      color: $primary-colour;
    }
  }
  &__top {
    display: flex;
    align-items: center;
    background-color: $white;
    padding: 0.5rem 1rem;
  }
  &__links {
    .newslist {
      .latestnews-items {
        flex-direction: column !important;
        width: 100%;
        margin-top: 1rem;
        @include bp(medium) {
          width: 400px;
        }
      }
      .latestnews-item {
        flex: 0 0 100% !important;
        max-width: 100% !important;
        padding: 0 0 1rem 0 !important;
        transform: translate(0px, 0px) !important;
        opacity: 1 !important;
        visibility: visible !important;
        & > a {
          position: relative;
          overflow: hidden;
          border: 0 !important;
          .newsinfo {
            padding: 1.5rem !important;
          }
        }
        .news {
          background-color: $white !important;
        }
      }
      .news {
        position: relative;
        z-index: 20;
      }
      .newsinfo {
        background-color: transparent;
        text-align: center;
        a {
          display: block;
        }
        img {
          width: 100%;
        }
      }
      .newsextra {
        text-align: left;
      }
      .link {
        display: none;
      }
    }
  }
  &__location,
  &__date {
    display: block;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    span {
      color: $secondary-colour !important;
    }
  }
  &__location {
    font-size: 1.2rem;
    font-weight: 500;
  }
}

.select-button-wrap {
  p {
    margin: 0;
  }
  .button {
    margin-right: 1rem;
  }
}
.button {
  &--select-event,
  &--select-event-close {
    @include bp_max(medium) {
      padding: 0.75rem 1rem !important;
      font-size: 0.7rem !important;
    }
  }
  &--select-event {
    margin: 0 0 0 auto;
    font-size: 0.8rem;
    color: $white !important;
  }
  &--select-event-close {
    display: flex !important;
    padding-right: 0;
    &:hover {
      p {
        color: $tertiary-colour;
      }
    }
    p {
      color: $primary-colour;
    }
    span {
      background: transparent !important;
      &:before,
      &:after {
        top: 0;
        background: $black !important;
      }
      &:before {
        @include rotate(45);
      }
      &:after {
        @include rotate(-45);
      }
    }
    &:hover {
      span:before,
      span:after {
        background-color: $tertiary-colour !important;
      }
    }
  }
}

/* Header Select
================================================== */
header {
  .events-list {
    @include bp(mlarge) {
      margin: 0 0.9375rem;
    }
  }
  .button {
    &--select-event {
      display: none;
      @include bp(mlarge) {
        display: inline-flex;
      }
      &:hover {
        background-color: $tertiary-colour;
      }
    }
  }
}

/* Navigation Select
================================================== */
.nav {
  &--main {
    .button {
      &--select-event {
        @include bp(xlarge) {
          display: none;
        }
      }
    }
  }
}
