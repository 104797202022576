/* Rounded Corners 
================================================== */
.rounded-corners,
.newslist .latestnews-item .news,
.slider-gallery .n2-ss-slider-wrapper-inside,
.map-card,
.cards .wf-column,
.card,
.ba-gallery .ba-gallery-items,
.events-list__top {
  overflow: hidden;
  border-radius: 16px !important;
}

.button,
.com-baforms-wrapper .ba-form-submit-btn,
button,
input[type='button'],
input[type='reset'],
input[type='submit'],
#banner .n2-ss-button-container a {
  border-radius: 100px !important;
  overflow: hidden;
}
